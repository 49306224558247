export default defineNuxtRouteMiddleware(async (to, from) => {

    // Maintenance check
    const config = useRuntimeConfig()
    const isMaintenance = config.public.isMaintenance
    if (isMaintenance || isMaintenance === 'true') {
      const allowedRoutes = ['/status/maintenance']
      if (!allowedRoutes.includes(to.path)) {
        return navigateTo('/status/maintenance')
      }
    } else if ((!isMaintenance || isMaintenance === 'false') && to.path === '/status/maintenance') {
      return navigateTo('/')
    }
  
    // Get user (if any)
    const user = useSupabaseUser()
  
    // Check if the user is logged in
    if(user && user.value) {
      // If user is logged in and tries to access trial, redirect to /
      if (to.path === '/account/trial') {
        return navigateTo('/')
      }
  
      // If user is logged in and tries to access logout, error or billing, allow it.
      // /settings/billing because that's where the user can go to upgrade (and redirected to when grace period is over)
      if (to.path === '/account/logout' || to.path === '/error' || to.path === '/settings/billing') {
        return
      }
  
      // Get user data first
      const supabase = useSupabaseClient()
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('company_id')
        .eq('id', user.value.id)
        .single()

      if (userError || !userData) {
        console.error('Error fetching user data:', userError)
        return navigateTo('/error?reason=fetching-user-data')
      }

      const { data: billingData, error: billingError } = await supabase
        .from('billing')
        .select('billing_cycle_end, grace_period_use, grace_period_access, billing_type, prompt_limit_per_cycle, billing_cycle_start')
        .eq('company_id', userData.company_id)
        .single()

      if (billingError || !billingData) {
        console.error('Error fetching billing data:', billingError)
        return navigateTo('/error?reason=fetching-billing-data')
      }
  
      const currentDate = new Date()
      // If the billing type is not trial, check if the grace period is over
      if(billingData.billing_type !== 'trial') {
  
        // account/signup is not allowed unless the user is on a trial
        if (to.path === '/account/signup') {
          return navigateTo('/')
        }
  
        const gracePeriodEnd = new Date(billingData.billing_cycle_end)
        gracePeriodEnd.setDate(gracePeriodEnd.getDate() + billingData.grace_period_access)
  
        // Check if the user is an admin
        const { data: userRoleData } = await supabase.rpc('get_my_claims')
        const isAdmin = userRoleData.app_role === 'admin'
  
        if (currentDate > gracePeriodEnd) {
          if (isAdmin) {
            // If the user is an admin, redirect to billing
            return navigateTo('/settings/billing')
          } else {
            // If the user is not an admin, redirect to error
            return navigateTo('/error?reason=billing-issue')
          }
        }
      } else {
        // The billing type is trial. 

        // If user is on trial and tries to access signup, they can because thats to end the trial and pay
        if(to.path === '/account/signup') {
            return 
          }
        // Check if usage/grace period is over
        const usageGracePeriodEnd = new Date(billingData.billing_cycle_end)
        usageGracePeriodEnd.setDate(usageGracePeriodEnd.getDate()) // no grace period for trial
  
        if (currentDate > usageGracePeriodEnd) {
          // If usage/grace period is over, redirect to error
          return navigateTo('/account/signup')
        }
  
        // Check if prompt limit has been reached
        const { count, error: countError } = await supabase
          .from('messages_log')
          .select('id', { count: 'exact' })
          .eq('company_id', userData.company_id)
          .eq('role', 'ai')
          .gte('created_at', billingData.billing_cycle_start)
          .lte('created_at', billingData.billing_cycle_end)
  
        if (countError) {
          // If error fetching prompt count, redirect to error
          console.error('Error fetching prompt count:', countError)
          return navigateTo('/error?reason=fetching-billing-data')
        }
  
        const usedPrompts = count || 0
        if (usedPrompts >= billingData.prompt_limit_per_cycle) {
          // If prompt limit has been reached, redirect to error
          return navigateTo('/account/signup')
        }
      }
    } else {
      // If the user is not logged in and the route is signup, cannot because not logged in.
      // so should go to trial instead. All other /account pages are allowed for non-logged in users.
      if (to.path === '/account/signup') {
        return navigateTo('/account/login')
      }
    }
  })